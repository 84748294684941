.App {
  text-align: center;
  background-color: #120d35;
  min-height: 100vh;
}

@font-face {
  font-family: "LuckiestGuy";
  src: local("LuckiestGuy-Regular"),
    url("./fonts/LuckiestGuy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Italic";
  src: local("Poppins-Italic"),
    url("./fonts/Poppins-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "OpenDyslexic-Bold";
  src: local("OpenDyslexic-Bold"),
    url("./fonts/OpenDyslexic-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "OpenDyslexic-Italic";
  src: local("OpenDyslexic-Italic"),
    url("./fonts/OpenDyslexic-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "OpenDyslexic-Regular";
  src: local("OpenDyslexic-Regular"),
    url("./fonts/OpenDyslexic-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./fonts/Roboto.ttf") format("truetype");
}
