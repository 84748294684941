body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* For mobile phones: */
[class*="containerSign"] {
  padding: 15px 20px;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .containerSign {
    padding: 30px 60px;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .containerSign {
    padding: 30px 68px;
  }
}
